.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background-color: var(--bg15);
  border: 1px solid var(--grey10);
  padding: 24px;
  width: 450px;

  > button {
    margin-top: 12px;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-bottom: 12px;
}
